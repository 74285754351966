import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Box, Typography, Button, TextField, Card, CardContent, CardActions, IconButton, Checkbox, FormGroup, FormControlLabel, Snackbar, Alert } from "@mui/material";
import Divider from "@mui/material/Divider";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadIcon from "@mui/icons-material/Download";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Grid from "@mui/material/Grid2";
import { getUserInfoFromToken } from "../services/authService";
import Loader from "../components/Loader";

dayjs.extend(customParseFormat);

export default function DailyFinancialReports() {
  const token = localStorage.getItem("authToken");
  const { role, username, team } = useMemo(() => getUserInfoFromToken(), []);
  const isUser = role === "user";
  const isTlead = role === "tlead";
  const [fromDate, setFromDate] = useState(dayjs().startOf("day"));
  const [toDate, setToDate] = useState(dayjs().endOf("day"));
  const [tempFromDate, setTempFromDate] = useState(fromDate);
  const [tempToDate, setTempToDate] = useState(toDate);
  const [data, setData] = useState({ leads: [], conversions: [] });
  const [finAccounts, setFinAccounts] = useState([]);
  const [finReviewData, setFinReviewData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingFin, setLoadingFin] = useState(false);
  const [aggConversions, setAggConversions] = useState({});
  const [aggSpend, setAggSpend] = useState({});
  const [copySuccess, setCopySuccess] = useState(false);

  const fetchData = useCallback(async (from, to) => {
    try {
      const params = {
        fromDate: from.format("YYYY-MM-DD HH:mm:ss"),
        toDate: to.format("YYYY-MM-DD HH:mm:ss"),
        itemsPerPage: 1000
      };
      const response = await axios.get("https://backend.moorpan.com/get-leads-and-conversions-new", { params, headers: { Authorization: `Bearer ${token}` } });
      let { leads, conversions } = response.data;
      const filterFn = (items) => {
        if (isUser) return items.filter((i) => i.custom4 === username);
        if (isTlead) return items.filter((i) => i.custom4 === username || (team && team.includes(i.custom4)));
        return items;
      };
      setData({ leads: filterFn(leads), conversions: filterFn(conversions) });
    } catch (error) {
      console.error(error);
    }
  }, [token, isUser, isTlead, username, team]);

  const fetchAccountFinGoogle = useCallback(async () => {
    try {
      const response = await axios.get("https://backend.moorpan.com/get-account-fin-google-new", { headers: { role, username, Authorization: `Bearer ${token}` } });
      let accounts = response.data || [];
      if (isUser) {
        accounts = accounts.filter((acc) => acc.buyer === username);
      } else if (isTlead && Array.isArray(team)) {
        accounts = accounts.filter((acc) => team.includes(acc.buyer));
      }
      setFinAccounts(accounts.map((acc, idx) => ({ ...acc, originalIndex: idx })));
    } catch (err) {
      console.error(err);
    }
  }, [token, role, username, team, isUser, isTlead]);

  useEffect(() => {
    async function loadAllData() {
      setLoadingData(true);
      setLoadingFin(true);
      try {
        await Promise.all([
          fetchData(fromDate, toDate),
          fetchAccountFinGoogle(),
          (async () => {
            const response = await axios.post("https://backend.moorpan.com/fin-google-review", {
              fromDate: fromDate.format("YYYY-MM-DD"),
              toDate: toDate.format("YYYY-MM-DD")
            }, { headers: { Authorization: `Bearer ${token}` } });
            setFinReviewData(response.data);
          })()
        ]);
      } catch (error) {
        console.error("Ошибка загрузки данных:", error);
      }
      setLoadingData(false);
      setLoadingFin(false);
    }
    loadAllData();
  }, [fromDate, toDate, token, fetchData, fetchAccountFinGoogle]);

  useEffect(() => {
    const worker = new Worker(new URL("../worker/aggregation.worker.js", import.meta.url));
    worker.onmessage = (e) => {
      const { type, payload } = e.data;
      if (type === "AGGREGATE_CONVERSIONS_RESULT") {
        setAggConversions(payload);
      }
      if (type === "AGGREGATE_SPEND_RESULT") {
        setAggSpend(payload);
      }
    };
    worker.postMessage({
      type: "AGGREGATE_CONVERSIONS",
      payload: { leads: data.leads, conversions: data.conversions }
    });
    worker.postMessage({
      type: "AGGREGATE_SPEND",
      payload: {
        finAccounts,
        fromDateStr: fromDate.format("YYYY-MM-DD"),
        toDateStr: toDate.format("YYYY-MM-DD")
      }
    });
    return () => worker.terminate();
  }, [data, finAccounts, fromDate, toDate]);

  const unionBuyers = useMemo(() => {
    const buyersFromLeads = data.leads.map((i) => i.custom4);
    const buyersFromSpend = finAccounts.map((acc) => acc.buyer);
    return Array.from(new Set([...buyersFromLeads, ...buyersFromSpend]));
  }, [data, finAccounts]);

  const [selectedBuyers, setSelectedBuyers] = useState([]);
  useEffect(() => {
    setSelectedBuyers(unionBuyers);
  }, [unionBuyers]);

  const handleQuickSelect = (newFrom, newTo) => {
    setFromDate(newFrom);
    setToDate(newTo);
    setTempFromDate(newFrom);
    setTempToDate(newTo);
  };
  const setToday = () => handleQuickSelect(dayjs().startOf("day"), dayjs().endOf("day"));
  const setYesterday = () => handleQuickSelect(dayjs().subtract(1, "day").startOf("day"), dayjs().subtract(1, "day").endOf("day"));
  const setLastWeek = () => handleQuickSelect(dayjs().subtract(1, "week").startOf("week"), dayjs().subtract(1, "week").endOf("week"));
  const setLastMonth = () => handleQuickSelect(dayjs().subtract(1, "month").startOf("month"), dayjs().subtract(1, "month").endOf("month"));
  const setThisWeek = () => handleQuickSelect(dayjs().startOf("week"), dayjs().endOf("week"));

  const handleCopy = (buyer) => {
    const buyerSpend = aggSpend[buyer] || {};
    const buyerConv = aggConversions[buyer] || { leads: {}, deposits: {} };
    const dateHeader = fromDate.isSame(toDate, "day")
      ? fromDate.format("DD/MM/YYYY")
      : `${fromDate.format("DD/MM/YYYY")} - ${toDate.format("DD/MM/YYYY")}`;
    let text = dateHeader + "\n" + buyer + "\n\n";
    for (const seller in buyerSpend) {
      const spendData = buyerSpend[seller];
      const totalSpend = Object.values(spendData).reduce((sum, val) => sum + val, 0);
      if (totalSpend === 0) continue;
      text += `[ ${seller} ]\n`;
      for (const geo in spendData) {
        if (spendData[geo] !== 0) {
          text += `Spend - ${spendData[geo].toFixed(2).replace(".", ",")} (${geo})\n`;
        }
      }
      text += "\n";
    }
    for (const geo in buyerConv.leads) {
      text += `Leads - ${buyerConv.leads[geo]} (${geo})\n`;
    }
    text += "\n";
    const geoSpend = {};
    for (const seller in buyerSpend) {
      const spendData = buyerSpend[seller];
      for (const geo in spendData) {
        geoSpend[geo] = (geoSpend[geo] || 0) + spendData[geo];
      }
    }
    for (const geo in buyerConv.leads) {
      const leadsCount = buyerConv.leads[geo] || 0;
      const totalSpend = geoSpend[geo] || 0;
      const cpl = leadsCount > 0 ? (totalSpend / leadsCount).toFixed(2).replace(".", ",") : "N/A";
      text += `CPL - ${cpl} (${geo})\n`;
    }
    text += "\n";
    for (const geo in buyerConv.deposits) {
      text += `Deposit - ${buyerConv.deposits[geo]} (${geo})\n`;
    }
    navigator.clipboard.writeText(text).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 1500);
    });
  };

  const exportCSV = () => {
    let csvContent = "data:text/csv;charset=utf-8,Buyer,Geo,Leads,Deposits,Seller,SellerSpend\n";
    for (const buyer in aggConversions) {
      if (!selectedBuyers.includes(buyer)) continue;
      const buyerData = aggConversions[buyer];
      const buyerSpend = aggSpend[buyer] || {};
      for (const seller in buyerSpend) {
        const spendData = buyerSpend[seller];
        const totalSpend = Object.values(spendData).reduce((sum, val) => sum + val, 0);
        if (totalSpend === 0) continue;
        for (const geo in spendData) {
          if (spendData[geo] !== 0) {
            const leadsCount = buyerData.leads[geo] || 0;
            const depositsCount = buyerData.deposits[geo] || 0;
            csvContent += `${buyer},${geo},${leadsCount},${depositsCount},${seller},${spendData[geo].toFixed(2)}\n`;
          }
        }
      }
    }
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "daily_financial_reports.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const overallLoading = loadingData || loadingFin;

  const applyDates = () => {
    setFromDate(tempFromDate);
    setToDate(tempToDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ p: 2, position: "relative", fontFamily: "Arial, sans-serif" }}>
        <Typography variant="h4" align="center" gutterBottom>
          Daily Financial Reports
        </Typography>
        {overallLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}>
            <Loader />
          </Box>
        ) : (
          <>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2, flexWrap: "wrap", mb: 2 }}>
              <IconButton onClick={() => { setTempFromDate(prev => prev.subtract(1, "day")); setTempToDate(prev => prev.subtract(1, "day")); }}>
                &larr;
              </IconButton>
              <DatePicker label="From Date" value={tempFromDate} onChange={newValue => setTempFromDate(newValue)} renderInput={params => <TextField {...params} />} />
              <DatePicker label="To Date" value={tempToDate} onChange={newValue => setTempToDate(newValue)} renderInput={params => <TextField {...params} />} />
              <IconButton onClick={() => { setTempFromDate(prev => prev.add(1, "day")); setTempToDate(prev => prev.add(1, "day")); }}>
                &rarr;
              </IconButton>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              <Button variant="contained" onClick={applyDates}>Apply Dates</Button>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 1, flexWrap: "wrap", mb: 2 }}>
              <Button variant="outlined" onClick={setToday}>Today</Button>
              <Button variant="outlined" onClick={setYesterday}>Yesterday</Button>
              <Button variant="outlined" onClick={setLastWeek}>Last Week</Button>
              <Button variant="outlined" onClick={setLastMonth}>Last Month</Button>
              <Button variant="outlined" onClick={setThisWeek}>This Week</Button>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              <Button variant="outlined" onClick={() => handleQuickSelect(dayjs().startOf("month"), dayjs().endOf("month"))}>This Month</Button>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 2 }}>
              <Button variant="outlined" onClick={() => setSelectedBuyers(unionBuyers)}>Select All</Button>
              <Button variant="outlined" onClick={() => setSelectedBuyers([])}>Unselect All</Button>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              <FormGroup row>
                {unionBuyers.map(buyer => (
                  <FormControlLabel key={buyer} control={<Checkbox checked={selectedBuyers.includes(buyer)} onChange={() => setSelectedBuyers(prev => prev.includes(buyer) ? prev.filter(b => b !== buyer) : [...prev, buyer])} />} label={buyer} />
                ))}
              </FormGroup>
            </Box>
            {(role === "admin" || role === "finance") && (
              <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                <Button variant="contained" startIcon={<DownloadIcon />} onClick={exportCSV}>Export CSV</Button>
              </Box>
            )}
            <Grid container spacing={2}>
              {unionBuyers.filter(buyer => selectedBuyers.includes(buyer)).map(buyer => (
                <Grid item xs={12} sm={6} md={3} key={buyer}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>{buyer}</Typography>
                      {aggSpend[buyer] &&
                        Object.keys(aggSpend[buyer])
                          .filter(seller => Object.values(aggSpend[buyer][seller]).reduce((sum, val) => sum + val, 0))
                          .map(seller => {
                            const spendData = aggSpend[buyer][seller];
                            const nonZeroGeos = Object.keys(spendData).filter(geo => spendData[geo] !== 0);
                            return (
                              <Box key={seller} sx={{ mb: 1 }}>
                                <Typography variant="subtitle1" component="span">[ {seller} ]</Typography>
                                <IconButton size="small" onClick={() => { const sellerName = seller.replace(/\s*\(.*\)/, ""); navigator.clipboard.writeText(sellerName); }}>
                                  <ContentCopyIcon fontSize="small" />
                                </IconButton>
                                {nonZeroGeos.map(geo => (
                                  <Typography variant="body2" key={geo} sx={{ fontFamily: "Arial, sans-serif", fontSize: "13px" }}>
                                    Spend - {spendData[geo].toFixed(2).replace(".", ",")} ({geo})
                                  </Typography>
                                ))}
                              </Box>
                            );
                          })}
                      <Box sx={{ mt: 1 }}>
                        {aggConversions[buyer] &&
                          Object.keys(aggConversions[buyer].leads || {}).map(geo => (
                            <Typography variant="body2" key={geo}>Leads - {aggConversions[buyer].leads[geo]} ({geo})</Typography>
                          ))}
                        {aggConversions[buyer] &&
                          Object.keys(aggConversions[buyer].deposits || {}).map(geo => (
                            <Typography variant="body2" key={geo}>Deposit - {aggConversions[buyer].deposits[geo]} ({geo})</Typography>
                          ))}
                      </Box>
                      {aggConversions[buyer] && (
                        <Box sx={{ mt: 1 }}>
                          {(() => {
                            const geoSpend = {};
                            if (aggSpend[buyer]) {
                              Object.keys(aggSpend[buyer]).forEach(seller => {
                                const spendData = aggSpend[buyer][seller];
                                Object.keys(spendData).forEach(geo => {
                                  geoSpend[geo] = (geoSpend[geo] || 0) + spendData[geo];
                                });
                              });
                            }
                            return Object.keys(aggConversions[buyer].leads || {}).map(geo => {
                              const leadsCount = aggConversions[buyer].leads[geo] || 0;
                              const totalSpend = geoSpend[geo] || 0;
                              const cpl = leadsCount > 0 ? (totalSpend / leadsCount).toFixed(2).replace(".", ",") : "N/A";
                              return (
                                <Typography variant="body2" key={geo}>{geo} CPL - {cpl}</Typography>
                              );
                            });
                          })()}
                        </Box>
                      )}
                      {(() => {
                        const buyerFinData = finReviewData.filter(item => item.custom === buyer);
                        if (buyerFinData.length) {
                          return (
                            <>
                              <Box sx={{ my: 2 }}>
                                <Divider />
                              </Box>
                              {buyerFinData.map((item, index) => (
                                <Box key={index} sx={{ mb: 1 }}>
                                  <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>Date: {item.date}</Typography>
                                  <Typography variant="body2">Expenses: {item.expenses}</Typography>
                                  <Typography variant="body2">Other Expenses: {item.otherExpenses}</Typography>
                                  <Typography variant="body2">Revenue: {item.revenue}</Typography>
                                  <Typography variant="body2">Profit: {item.profit}</Typography>
                                </Box>
                              ))}
                            </>
                          );
                        }
                        return null;
                      })()}
                    </CardContent>
                    <CardActions>
                      <IconButton onClick={() => handleCopy(buyer)}>
                        <ContentCopyIcon />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Snackbar open={copySuccess} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
              <Alert severity="success" sx={{ width: "100%" }}>Copied to clipboard!</Alert>
            </Snackbar>
          </>
        )}
      </Box>
    </LocalizationProvider>
  );
}
