import React, { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import { createTheme } from "@mui/material/styles";
import BarChartIcon from "@mui/icons-material/BarChart";
import PeopleIcon from "@mui/icons-material/People";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import lightLogo from "./images/dark-logo.svg";
import darkLogo from "./images/logo.svg";
import MediaBuyerAnalytics from "./pages/MediaBuyerAnalytics";
import AdvertiserAnalytics from "./pages/AdvertiserAnalytics";
import AccountsAnalitics from "./pages/AccountsAnalitics";
import DomainsAnalitics from "./pages/DomainsAnalitics";
import GlobalStyles from "@mui/material/GlobalStyles";
import { useNavigate } from "react-router-dom";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DomainIcon from "@mui/icons-material/Domain";
import Leaderboard from "./pages/Leaderboard";
import TrashHoldDomains from "./pages/TrashHoldDomains";
import InsightsIcon from "@mui/icons-material/Insights";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import AdminSetting from "./pages/AdminSetting";
import FinancialAnalytics from './pages/FinancialAnalytics';
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import { getUserInfoFromToken, logout } from "./services/authService";

import AccountsAnaliticsGoogle from './pages/AccountsAnaliticsGoogle';
import FinancialAnalyticsGoogle from './pages/FinancialAnalyticsGoogle';
import LeadsReports from "./pages/LeadsReports";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import Leadsboard from "./pages/Leadsboard";
import DailyFinancialReports from "./pages/DailyFinancialReports";
import MultiLeadBoard from "./pages/MultiLeadBoard";
import FarmersTable from "./pages/FarmersTable";

const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      "& main.MuiBox-root": {
        minWidth: "80%",
        boxSizing: "border-box",
      },
    }}
  />
);

const getTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: "data-toolpad-color-scheme",
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: "Poppins, Arial, sans-serif",
  },
});

export default function DashboardLayoutBranding() {
  const [pathname, setPathname] = useState("/");
  const [currentLogo, setCurrentLogo] = useState(lightLogo);
  const navigate = useNavigate();

  const { role, username } = getUserInfoFromToken();
  const email = username ? `${username}@click2ftds.com` : "Anonymous";

  const AUTHENTICATION = {
    signIn: () => {},
    signOut: () => {
      logout();
      navigate("/login");
    },
  };

  useEffect(() => {
    const handleStorageChange = () => {

    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const NAVIGATION = useMemo(
    () => [
      ...(role === "admin" || role === "user" || role === "tlead"
        ? [
            {
              segment: "leaderboard",
              title: "Lead's board",
              icon: <BarChartIcon />,
            },
          ]
        : []),
        ...(role === "admin" || username === "Kir" || username === "Korea"
          ? [
              {
                segment: "multi-leads-new",
                title: "Multi Lead's board",
                icon: <BarChartIcon />,
              },
            ]
          : []),
      ...(role === "admin" || role === "user" || role === "finance" || role === "tlead"
        ? [
            {
              segment: "media-buyer-analytics",
              title: "Media Buyer Analytics",
              icon: <PeopleIcon />,
            },
          ]
        : []),
      ...(role === "admin"
        ? [
            {
              segment: "advertiser-analytics",
              title: "Advertiser Analytics",
              icon: <BarChartIcon />,
            },
          ]
        : []),
      ...(role === "admin" || role === "user" || role === "finance" || role === "accm" || role === "tlead"
        ? [
            {
              segment: "accounts-analytics-google",
              title: "Accounts Analytics",
              icon: <AccountBalanceIcon />,
            },
          ]
        : []),
        ...(role === "admin" || role === "farm"
          ? [
              {
                segment: "farmers",
                title: "Farmers Table",
                icon: <AccountBalanceIcon />,
              },
            ]
          : []),
      ...(role === 'admin' || role === 'finance' || role === "user" || role === "tlead" || role === "accm"
        ? [
            {
              segment: "financial",
              title: "Financial",
              icon: <CurrencyExchangeIcon />,
              kind: "page",
              children: [
                {
                  segment: "financial-analytics-google",
                  title: "Financial Analytics",
                  icon: <InsightsIcon />,
                  kind: "page",
                },
                {
                  segment: "daily-financial-reports",
                  title: "Daily Report's",
                  icon: <CurrencyExchangeIcon />,
                  kind: "page",
                },
              ],
            },
          ]
        : []),
      ...(role === "admin" || role === "user" || role === "tlead"
        ? [
            {
              segment: "domains",
              title: "Domains",
              icon: <DomainIcon />,
              kind: "page",
              children: [
                {
                  segment: "domains-analytics",
                  title: "Domains Analytics",
                  icon: <InsightsIcon />,
                  kind: "page",
                },
                {
                  segment: "trash-hold-domains",
                  title: "Trash Hold Domains",
                  icon: <CurrencyExchangeIcon />,
                  kind: "page",
                },
              ],
            },
          ]
        : []),
      ...(role === "admin"
        ? [
            {
              segment: "admin-setting",
              title: "Admin Setting",
              icon: <SettingsApplicationsIcon />,
            },
          ]
        : []),
      ...(role === "admin" || role === "user" || role === "tlead"
        ? [
            {
              segment: "leads-reports",
              title: "Leads Reports",
              icon: <ReportGmailerrorredIcon />,
            },
          ]
        : []),
    ],
    [role]
  );

  const router = useMemo(() => {
    return {
      pathname,
      searchParams: new URLSearchParams(),
      navigate: (path) => setPathname(String(path)),
    };
  }, [pathname]);


  useEffect(() => {
    const updateLogo = () => {
      const scheme = document.documentElement.getAttribute("data-toolpad-color-scheme");
      if (scheme === "dark") {
        setCurrentLogo(darkLogo);
      } else {
        setCurrentLogo(lightLogo);
      }
    };

    updateLogo();

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "attributes" && mutation.attributeName === "data-toolpad-color-scheme") {
          updateLogo();
        }
      }
    });

    observer.observe(document.documentElement, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  const renderContent = () => {
    switch (pathname) {
      case "/media-buyer-analytics":
        return <MediaBuyerAnalytics />;
      case "/advertiser-analytics":
        return <AdvertiserAnalytics />;
      case "/accounts-analytics":
        return <AccountsAnalitics />;
      case "/domains/domains-analytics":
        return <DomainsAnalitics />;
      case "/domains/trash-hold-domains":
        return <TrashHoldDomains />;
      case "/leaderboard":
        return <Leadsboard />;
      case "/admin-setting":
        return <AdminSetting />;
      case "/financial-analytics":
        return <FinancialAnalytics />;
      case "/accounts-analytics-google":
        return <AccountsAnaliticsGoogle />;
      case "/financial/financial-analytics-google":
        return <FinancialAnalyticsGoogle />;
      case "/financial/daily-financial-reports":
        return <DailyFinancialReports />;
      case "/leads-reports":
        return <LeadsReports />;
      case "/multi-leads-new":
          return <MultiLeadBoard />;
      case "/farmers":
        return <FarmersTable />;
      default:
        if (role === 'finance') {
          return <MediaBuyerAnalytics />;
        } else if (role === 'accm') {
          return <AccountsAnalitics />;
        } else if (role === 'farm') {
          return <FarmersTable />;
        } else {
          return <Leaderboard />;
        }
    }
  };

  return (
    <AppProvider
      navigation={NAVIGATION}
      theme={getTheme}
      branding={{
        logo: <img src={currentLogo} alt="CRM2FTDS Logo" />,
        title: "",
      }}
      router={router}
      authentication={AUTHENTICATION}
      session={{
        user: { name: username || "Anonymous", email },
      }}
    >
      {inputGlobalStyles}
      <DashboardLayout>
        <Box sx={{ p: 2 }}>{renderContent()}</Box>
      </DashboardLayout>
    </AppProvider>
  );
}
