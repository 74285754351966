import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  useMediaQuery,
  IconButton
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';
import { getUserInfoFromToken } from '../services/authService';
import { dataGridStyles } from '../styles';

const FarmersTable = () => {
  const token = localStorage.getItem('authToken');
  const userInfo = useMemo(() => getUserInfoFromToken(), []);
  const { role, username } = userInfo;

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs().startOf('day'));
  const [toDate, setToDate] = useState(dayjs().endOf('day'));
  const [farmers, setFarmers] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [selectedFarmer, setSelectedFarmer] = useState('');
  const [selectedBuyer, setSelectedBuyer] = useState('');
  const [selectedSeller, setSelectedSeller] = useState('');

  const isMobile = useMediaQuery('(max-width:600px)');

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        startDate: fromDate.format('YYYY-MM-DD'),
        endDate: toDate.format('YYYY-MM-DD')
      };
      
      const response = await axios.get('https://backend.moorpan.com/get-farm-data', {
        params,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      setData(response.data);
      setFilteredData(response.data);
      
      const uniqueFarmers = [...new Set(response.data.map(item => item.Farmer))];
      const uniqueBuyers = [...new Set(response.data.map(item => item.Bayer))];
      const uniqueSellers = [...new Set(response.data.map(item => item.Seller))];
      setFarmers(uniqueFarmers);
      setBuyers(uniqueBuyers);
      setSellers(uniqueSellers);
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    } finally {
      setLoading(false);
    }
  }, [fromDate, toDate, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    let filtered = data;

    if (selectedFarmer) {
      filtered = filtered.filter(item => item.Farmer === selectedFarmer);
    }
    if (selectedBuyer) {
      filtered = filtered.filter(item => item.Bayer === selectedBuyer);
    }
    if (selectedSeller) {
      filtered = filtered.filter(item => item.Seller === selectedSeller);
    }

    setFilteredData(filtered);
  }, [selectedFarmer, selectedBuyer, selectedSeller, data]);

  const setToday = () => {
    setFromDate(dayjs().startOf('day'));
    setToDate(dayjs().endOf('day'));
  };

  const setYesterday = () => {
    setFromDate(dayjs().subtract(1, 'day').startOf('day'));
    setToDate(dayjs().subtract(1, 'day').endOf('day'));
  };

  const setLastWeek = () => {
    setFromDate(dayjs().subtract(1, 'week').startOf('week'));
    setToDate(dayjs().subtract(1, 'week').endOf('week'));
  };

  const setLastMonth = () => {
    setFromDate(dayjs().subtract(1, 'month').startOf('month'));
    setToDate(dayjs().subtract(1, 'month').endOf('month'));
  };

  const setThisWeek = () => {
    setFromDate(dayjs().startOf('week'));
    setToDate(dayjs().endOf('week'));
  };

  const setThisMonth = () => {
    setFromDate(dayjs().startOf('month'));
    setToDate(dayjs().endOf('month'));
  };

  const columns = [
    { field: 'Farmer', headerName: 'Farmer', minWidth: 120, flex: 1 },
    { field: 'Bayer', headerName: 'Bayer', minWidth: 120, flex: 1 },
    { field: 'Date', headerName: 'Date', minWidth: 120, flex: 1 },
    { field: 'Email', headerName: 'Email', minWidth: 180, flex: 1 },
    { field: 'ID Account', headerName: 'ID Account', minWidth: 160, flex: 1 },
    { field: 'Seller', headerName: 'Seller', minWidth: 120, flex: 1 },
    { field: 'Status', headerName: 'Status', minWidth: 120, flex: 1 },
    { field: 'Card', headerName: 'Card', minWidth: 120, flex: 1 },
    { field: 'First replenishment $', headerName: 'First replenishment $', minWidth: 120, flex: 1 }
  ];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ position: 'relative', padding: 2 }}>
        <Typography variant='h4' align='center' gutterBottom sx={{ margin: '0 0 40px 0' }}>
          Farm Table
        </Typography>

        <Box>
          <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2, mb: 2, alignItems: 'center', justifyContent: 'center' }}>
            <DatePicker label='From' value={fromDate} onChange={setFromDate} format='DD/MM/YYYY' />
            <DatePicker label='To' value={toDate} onChange={setToDate} format='DD/MM/YYYY' />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2, mb: 2, alignItems: 'center', justifyContent: 'center'  }}>
            <Button onClick={setToday} variant='outlined'>Today</Button>
            <Button onClick={setYesterday} variant='outlined'>Yesterday</Button>
            <Button onClick={setLastWeek} variant='outlined'>Last Week</Button>
            <Button onClick={setLastMonth} variant='outlined'>Last Month</Button>
            <Button onClick={setThisWeek} variant='outlined'>This Week</Button>
            <Button onClick={setThisMonth} variant='outlined'>This Month</Button>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2, mb: 2, alignItems: 'center', justifyContent: 'center'  }}>
            <Select value={selectedFarmer} onChange={(e) => setSelectedFarmer(e.target.value)} displayEmpty>
              <MenuItem value=''>Select farmer</MenuItem>
              {farmers.map(farmer => (
                <MenuItem key={farmer} value={farmer}>{farmer}</MenuItem>
              ))}
            </Select>
            <Select value={selectedBuyer} onChange={(e) => setSelectedBuyer(e.target.value)} displayEmpty>
              <MenuItem value=''>Select bayer</MenuItem>
              {buyers.map(buyer => (
                <MenuItem key={buyer} value={buyer}>{buyer}</MenuItem>
              ))}
            </Select>
            <Select value={selectedSeller} onChange={(e) => setSelectedSeller(e.target.value)} displayEmpty>
              <MenuItem value=''>Select seller</MenuItem>
              {sellers.map(seller => (
                <MenuItem key={seller} value={seller}>{seller}</MenuItem>
              ))}
            </Select>
            <IconButton onClick={fetchData} color='primary'>
              <RefreshIcon />
            </IconButton>
          </Box>
        </Box>

        <DataGrid
          rows={filteredData.map((row, index) => ({ id: index, ...row }))}
          columns={columns}
          pageSize={10}
          loading={loading}
          sx={{ ...dataGridStyles, height: 500, width: '100%' }}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default FarmersTable;