import React, { useState, useEffect, useMemo } from 'react';
import {
  DataGrid,
  getGridStringOperators,
} from '@mui/x-data-grid';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stepper,
  Step,
  StepLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material';
import { dataGridStyles } from '../styles';
import Loader from '../components/Loader';
import axios from 'axios';
import dayjs from 'dayjs';
import { getUserInfoFromToken } from '../services/authService';
import { red } from '@mui/material/colors';

const TrashHoldDomains = () => {
  const token = localStorage.getItem('authToken');
  const { role, username, team } = useMemo(() => getUserInfoFromToken(), []);

  const [allDomains, setAllDomains] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [openDevopsDialog, setOpenDevopsDialog] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [fileMatches, setFileMatches] = useState([]);
  const [fileContent, setFileContent] = useState('');
  const [fileName, setFileName] = useState('Файл не выбран');

  const stringOnlyContainsOperators = getGridStringOperators().filter(
    (operator) => operator.value === 'contains'
  );

  useEffect(() => {
    const fetchDomainsData = async () => {
      setLoading(true);
      try {
        const params = { role, username };
        if (role === 'tlead' && Array.isArray(team)) {
          params.team = JSON.stringify(team);
        }

        const response = await axios.get(
          'https://backend.moorpan.com/get-trash-hold-domains',
          {
            params,
            headers: {
              role,
              username,
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const updatedData = Object.entries(response.data).reduce(
          (acc, [project, domains]) => {
            const filteredDomains = domains.map((domain) => ({
              ...domain,
              project,
            }));
            if (filteredDomains.length > 0) {
              acc[project] = filteredDomains;
            }
            return acc;
          },
          {}
        );

        setAllDomains(updatedData);
      } catch (err) {
        setError('Ошибка при загрузке данных');
      } finally {
        setLoading(false);
      }
    };

    fetchDomainsData();
  }, [role, username, team, token]);

  const calculateLifeDuration = (dateChecked, dateDead) => {
    if (!dateChecked) return null;
    const checkedDate = dayjs(dateChecked);
    if (dateDead !== null) {
      const deadDate = dayjs(dateDead);
      return deadDate.diff(checkedDate, 'day');
    }
    return dayjs().diff(checkedDate, 'day');
  };

  const getRowClassName = (lifeDuration) => {
    if (lifeDuration === null) return '';
    if (lifeDuration <= 7) return 'row-red';
    if (lifeDuration <= 14) return 'row-yellow';
    if (lifeDuration <= 31) return 'row-orange';
    return 'row-green';
  };

  const handleDeleteFromTH = async (domainRow) => {
    const confirmDelete = window.confirm(
      `Вы уверены, что хотите удалить домен ${domainRow.domain} из Trash Hold?`
    );
    if (!confirmDelete) return;

    try {
      await axios.put(
        'https://backend.moorpan.com/update-domain',
        {
          id: domainRow.id,
          domain: domainRow.domain,
          status: 'Проиндексирован',
          date_dead: null,
          date_ads_dead: null,
        },
        {
          headers: {
            role,
            username,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAllDomains((prev) => {
        const updated = { ...prev };
        Object.keys(updated).forEach((project) => {
          updated[project] = updated[project].filter(
            (item) => item.domain !== domainRow.domain
          );
          if (updated[project].length === 0) {
            delete updated[project];
          }
        });
        return updated;
      });
    } catch (err) {
      console.error('Ошибка удаления домена', err);
      alert('Ошибка при удалении домена');
    }
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
      hide: true,
    },
    {
      field: 'domain',
      headerName: 'Domain',
      flex: 1,
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: 'project',
      headerName: 'Buyer',
      flex: 1,
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: 'status_ads',
      headerName: 'Status Ads',
      flex: 1,
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: 'date_checked',
      headerName: 'Date start index',
      flex: 1,
      type: 'dateTime',
      valueGetter: (params) => (params ? new Date(params) : null),
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: 'date_dead',
      headerName: 'Date end index',
      flex: 1,
      type: 'dateTime',
      valueGetter: (params) => (params ? new Date(params) : null),
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: 'lifeDuration',
      headerName: 'Life Duration (days)',
      flex: 1,
      renderCell: (cellValues) => {
        const { date_checked, date_dead } = cellValues.row;
        return date_checked
          ? calculateLifeDuration(date_checked, date_dead)
          : 'No Data';
      },
      sortable: false,
      filterOperators: stringOnlyContainsOperators,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (cellValues) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleDeleteFromTH(cellValues.row)}
        >
          Delete from TH
        </Button>
      ),
    },
  ];

  const handleOpenDevopsDialog = () => {
    setActiveStep(0);
    setFileMatches([]);
    setFileContent('');
    setFileName('Файл не выбран');
    setOpenDevopsDialog(true);
  };

  const handleCloseDevopsDialog = () => {
    setOpenDevopsDialog(false);
    setActiveStep(0);
    setFileMatches([]);
    setFileContent('');
    setFileName('Файл не выбран');
  };

  const handleDialogNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  const handleDialogBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleDevopsSubmit = async () => {
    const remainingRecords = Object.values(allDomains)
      .flat()
      .filter((item) => item.project === username);

    const trashPayload = {
      project: username,
      domains: remainingRecords.map((item) => item.domain),
    };

    try {
      await axios.post('https://unalterability.com/trash.php', trashPayload);
      alert('Домены удалены успешно!');

      const idsToDelete = remainingRecords.map((item) => item.id);
      const deletePayload = { ids: idsToDelete };

      await axios.post('https://backend.moorpan.com/delete-domains', deletePayload, {
        headers: {
          role,
          username,
          Authorization: `Bearer ${token}`,
        },
      });

      handleCloseDevopsDialog();
    } catch (err) {
      console.error('Ошибка при отправке запроса на удаление:', err);
      alert('Ошибка при отправке запроса');
    }
  };

  // Функция для конвертации RTF в обычный текст (наивная реализация)
  const extractPlainTextFromRTF = (rtf) => {
    // Заменяем управляющие последовательности переноса строки (\par или \pard) на \n
    let text = rtf.replace(/\\par[d]?/gi, '\n');
    // Удаляем управляющие последовательности, например \b, \i, \fs24 и т.п.
    text = text.replace(/\\[a-z]+\d* ?/gi, '');
    // Удаляем escape-последовательности вида \'xx
    text = text.replace(/\\'[0-9a-f]{2}/gi, '');
    // Убираем фигурные скобки
    text = text.replace(/[{}]/g, '');
    // Нормализуем переносы строк и пробелы
    return text.replace(/\n+/g, '\n').trim();
  };

  // Функция для извлечения доменов из текста с помощью регулярного выражения
  const extractDomains = (content) => {
    const domainRegex = /[a-z0-9.-]+\.[a-z]{2,}/gi;
    const matches = content.match(domainRegex);
    return matches ? matches.map((domain) => domain.trim()) : [];
  };

  // Общая функция для обработки выбранного файла
  const processFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      let content = event.target.result;
      if (file.name.toLowerCase().endsWith('.rtf')) {
        content = extractPlainTextFromRTF(content);
      }
      setFileContent(content);
    };
    reader.readAsText(file);
  };

  // Обработчик выбора файла (input или drag'n'drop)
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setFileName(file.name);
    processFile(file);
  };

  // Обработчики для drag'n'drop
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'copy';
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    if (!file) return;
    setFileName(file.name);
    processFile(file);
  };

  // Обработчик сравнения доменов
  const handleCompareDomains = () => {
    if (!fileContent) {
      alert('Сначала загрузите TXT или RTF файл');
      return;
    }
    const domainsFromFile = extractDomains(fileContent);
    const userDomains = Object.values(allDomains)
      .flat()
      .filter((item) => item.project === username);
    const matches = userDomains.filter((item) =>
      domainsFromFile.includes(item.domain)
    );
    setFileMatches(matches);
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error" align="center">
        {error}
      </Typography>
    );
  }

  const rows = Object.values(allDomains).flat();

  return (
    <Box>
      <Typography variant="h4" align="center" gutterBottom>
        Trash Hold Domains
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
        <Button variant="contained" color="primary" onClick={handleOpenDevopsDialog}>
          Отправить домены на удаление devops
        </Button>
      </Box>

      <Box sx={{ marginBottom: 4 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: { paginationModel: { pageSize: 25, page: 0 } },
            columns: { columnVisibilityModel: { id: false } },
          }}
          pageSizeOptions={[25, 50, 100, 250, { value: -1, label: 'All' }]}
          getRowId={(row) => row.domain}
          getRowClassName={(params) => {
            const { date_checked, date_dead } = params.row || {};
            if (!date_checked) return '';
            const lifeDuration = calculateLifeDuration(date_checked, date_dead);
            return getRowClassName(lifeDuration);
          }}
          sx={dataGridStyles}
        />
      </Box>

      <Dialog open={openDevopsDialog} onClose={handleCloseDevopsDialog} fullWidth maxWidth="md">
        <Box
          sx={{ position: 'relative', minHeight: '300px', p: 2 }}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <DialogTitle>Отправка доменов на удаление devops</DialogTitle>
          <DialogContent>
            <Stepper activeStep={activeStep} alternativeLabel>
              <Step>
                <StepLabel>Выбор доменов</StepLabel>
              </Step>
              <Step>
                <StepLabel>Подтверждение удаления</StepLabel>
              </Step>
            </Stepper>

            {activeStep === 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Список ваших доменов:
                </Typography>
                <Paper variant="outlined" sx={{ maxHeight: 200, overflow: 'auto', mb: 2 }}>
                  <List dense>
                    {Object.values(allDomains)
                      .flat()
                      .filter((item) => item.project === username)
                      .map((item) => (
                        <ListItem key={item.domain} divider>
                          <ListItemText primary={item.domain} />
                          <Button variant="outlined" color="secondary" size="small" onClick={() => handleDeleteFromTH(item)}>
                            Delete from TH
                          </Button>
                        </ListItem>
                      ))}
                  </List>
                </Paper>

                <Typography variant="subtitle1" gutterBottom>
                  Или загрузите TXT или RTF файл (один домен в строке):
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Button variant="contained" component="label" color="primary">
                    {fileName}
                    <input type="file" hidden accept=".txt,.rtf" onChange={handleFileUpload} />
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleCompareDomains} sx={{ ml: 2 }}>
                    Сравнить домены
                  </Button>
                </Box>

                {fileMatches.length > 0 && (
                  <>
                    <Typography variant="subtitle1" sx={{ mt: 2 }}>
                      Найденные совпадения:
                    </Typography>
                    <Paper variant="outlined" sx={{ maxHeight: 200, overflow: 'auto', mb: 2 }}>
                      <List dense>
                        {fileMatches.map((item) => (
                          <ListItem key={item.domain} divider>
                            <ListItemText primary={item.domain} />
                            <Button variant="outlined" color="secondary" size="small" onClick={() => handleDeleteFromTH(item)}>
                              Delete from TH
                            </Button>
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  </>
                )}
              </Box>
            )}

            {activeStep === 1 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Внимание!
                </Typography>
                <Typography variant="body1">
                  Домены будут удалены в течение текущего дня. Нажмите "Submit", чтобы продолжить.
                </Typography>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            {activeStep > 0 && (
              <Button onClick={handleDialogBack} color="primary">
                Назад
              </Button>
            )}
            {activeStep < 1 && (
              <Button onClick={handleDialogNext} color="primary" variant="contained">
                Далее
              </Button>
            )}
            {activeStep === 1 && (
              <Button onClick={handleDevopsSubmit} variant="contained" color="primary">
                Submit
              </Button>
            )}
            <Button onClick={handleCloseDevopsDialog} sx={{ backgroundColor: red[400], color: 'white' }}>
              Отмена
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
};

export default TrashHoldDomains;
