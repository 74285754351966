import React, { useState, useEffect, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Typography,
  Button,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import axios from "axios";

const MultiLeadBoard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs().startOf("day"));
  const [toDate, setToDate] = useState(dayjs().endOf("day"));

  const fetchLeads = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        fromDate: fromDate.format("YYYY-MM-DD HH:mm:ss"),
        toDate: toDate.format("YYYY-MM-DD HH:mm:ss"),
      };

      const response = await axios.get(
        "https://backend.moorpan.com/multi-leads",
        { params }
      );

      setData(response.data.items || []);
    } catch (error) {
      console.error("Ошибка при загрузке данных лидов:", error);
    } finally {
      setLoading(false);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    fetchLeads();
  }, [fetchLeads]);

  const columns = [
    { field: "countryName", headerName: "Country", minWidth: 100, flex: 1 },
    { field: "cityName", headerName: "City", minWidth: 100, flex: 1 },
    { field: "custom", headerName: "Custom", minWidth: 100, flex: 1 },
    { field: "custom1", headerName: "Custom1", minWidth: 100, flex: 1 },
    { field: "custom2", headerName: "Custom2", minWidth: 100, flex: 1 },
    { field: "custom3", headerName: "Custom3", minWidth: 100, flex: 1 },
    { field: "custom4", headerName: "Custom4", minWidth: 100, flex: 1 },
    { field: "custom5", headerName: "Custom5", minWidth: 100, flex: 1 },
    {
      field: "signupDate",
      headerName: "Signup Date",
      minWidth: 150,
      flex: 1,
      valueGetter: (params) =>
        params ? dayjs(params).format("DD/MM/YYYY HH:mm:ss") : "",
    },
    { field: "email", headerName: "Email", minWidth: 150, flex: 1 },
    { field: "customerID", headerName: "Customer ID", minWidth: 100, flex: 1 },
    { field: "countryCode", headerName: "Country Code", minWidth: 100, flex: 1 },
    { field: "saleStatus", headerName: "Sale Status", minWidth: 100, flex: 1 },
    { field: "campaignHash", headerName: "Offer Hash", minWidth: 100, flex: 1 },
    {
      field: "hasFTD",
      headerName: "Has Conversion",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => (params.value ? "Yes" : "No"),
    },
  ];

  const processedData = data.map((row, index) => ({
    ...row,
    id: `${index}`,
  }));

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Multi Lead Board
        </Typography>

        <Box sx={{ display: "flex", gap: 2, mb: 2, justifyContent: "center" }}>
          <DatePicker
            label="From Date"
            value={fromDate}
            onChange={setFromDate}
            format="DD/MM/YYYY"
          />
          <DatePicker
            label="To Date"
            value={toDate}
            onChange={setToDate}
            format="DD/MM/YYYY"
          />
          <Button variant="contained" onClick={fetchLeads}>
            Refresh
          </Button>
        </Box>

        <DataGrid
          rows={processedData}
          columns={columns}
          pageSize={10}
          loading={loading}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default MultiLeadBoard;
