import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { DataGrid, getGridStringOperators } from "@mui/x-data-grid";
import {
  TextField,
  Box,
  Typography,
  Divider,
  Select,
  MenuItem,
  FormControl,
  Button,
  useMediaQuery,
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import axios from "axios";
import { Doughnut, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import Loader from "../components/Loader";
import { dataGridStyles } from "../styles";
import RefreshIcon from "@mui/icons-material/Refresh";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { getUserInfoFromToken } from "../services/authService";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement
);

const AccountsAnalyticsGoogle = () => {
  const token = localStorage.getItem("authToken");
  const userInfo = useMemo(() => getUserInfoFromToken(), []);
  const { role, username, team } = userInfo;

  const [accountsData, setAccountsData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [searchEmail, setSearchEmail] = useState("");
  const [searchId, setSearchId] = useState("");
  const [searchDomain, setSearchDomain] = useState("");
  const [startDate, setStartDate] = useState(dayjs().startOf("day"));
  const [endDate, setEndDate] = useState(dayjs().endOf("day"));
  const [lifetimeFilter, setLifetimeFilter] = useState("");
  const [sellerFilter, setSellerFilter] = useState("");
  const [projectFilter, setProjectFilter] = useState("");
  const [accountTypeFilter, setAccountTypeFilter] = useState([]);
  const [cloFilter, setCloFilter] = useState([]);
  const [offerFilter, setOfferFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [totalCostRange, setTotalCostRange] = useState("");
  const [accountGeoFilter, setAccountGeoFilter] = useState([]);
  const [trafficGeoFilter, setTrafficGeoFilter] = useState([]);
  const [isExcludedFilterActive, setIsExcludedFilterActive] = useState(false);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);

  const [isMetricsVisible, setIsMetricsVisible] = useState(false);

  const [selectedSeller, setSelectedSeller] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [statusHistory, setStatusHistory] = useState(null);
  const [dialogError, setDialogError] = useState(null);

  const [openTotalDialog, setOpenTotalDialog] = useState(false);

  const [openDailyDialog, setOpenDailyDialog] = useState(false);
  const [dailyDialogData, setDailyDialogData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const isMobile = useMediaQuery("(max-width:600px)");
  const tableName = "AccountsAnalyticsGoogle";

  const EXCLUDED_STATUSES = [
    "компрометирующий сайт",
    "обход системы",
    "бизнес модель",
    "бизнес практика",
    "модерация (вайт/блэк)",
    "модерация (вайт/вайт)",
  ];

  const RED_STATUSES = [
    "обход системы",
    "бизнес модель",
    "бизнес практика",
    "компрометирующий сайт",
  ];

  const stringOnlyContainsOperators = getGridStringOperators().filter(
    (op) => op.value === "contains"
  );

  const dataGridStylesSticky = {
    height: 600,
    "& .MuiDataGrid-columnHeaders": {
      position: "sticky",
      top: 0,
      zIndex: 1000,
    },
    "& .MuiDataGrid-virtualScroller": {
      overflowY: "auto",
    },
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [columnWidths, setColumnWidths] = useState({});

  const baseColumns = useMemo(() => {
    return [
      {
        field: "dateOfIssue",
        headerName: "Data of issue",
        minWidth: 60,
        valueFormatter: (params) =>
          params
            ? dayjs(params, "DD.MM.YYYY").format("DD/MM/YYYY")
            : "",
        sortable: true,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "seller",
        headerName: "Seller",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "accountType",
        headerName: "Account Type",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "project",
        headerName: "Buyer",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "idAccount",
        headerName: "ID Account",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "clo",
        headerName: "CLO",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "accountGeo",
        headerName: "Account Geolocation",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "mccOrganizationName",
        headerName: "MCC Organization Name",
        minWidth: 60,
        sortable: false,
      },
      {
        field: "paymentMethodOrIDMCC",
        headerName: "Payment Method or ID MCC",
        minWidth: 60,
        sortable: false,
      },
      {
        field: "domain",
        headerName: "Domain",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "offer",
        headerName: "Offer",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "statusAds",
        headerName: "Status Ads",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "trafficGeo",
        headerName: "Traffic GEO",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "creoID",
        headerName: "Creative ID",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "dayliSpend",
        headerName: "Dayli Spend",
        minWidth: 60,
        sortable: false,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "totalLeads",
        headerName: "Total Leads",
        minWidth: 60,
        sortable: true,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "totalDeposit",
        headerName: "Total Deposit",
        minWidth: 60,
        sortable: true,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "totalCost",
        headerName: "Total Cost",
        minWidth: 60,
        sortable: true,
        filterOperators: stringOnlyContainsOperators,
        sortComparator: (v1, v2) => {
          const num1 = parseFloat(v1) || 0;
          const num2 = parseFloat(v2) || 0;
          return num1 - num2;
        },
      },
      {
        field: "cpl",
        headerName: "CPL",
        minWidth: 60,
        valueFormatter: (params) => {
          const val = parseFloat(params);
          if (isNaN(val)) return 0;
          return val.toFixed(2);
        },
        sortable: true,
        filterOperators: stringOnlyContainsOperators,
        sortComparator: (v1, v2) => {
          const num1 = parseFloat(v1) || 0;
          const num2 = parseFloat(v2) || 0;
          return num1 - num2;
        },
      },
      {
        field: "lifeDuration",
        headerName: "Life Duration Account (days)",
        minWidth: 60,
        renderCell: (params) => (params.value != null ? params.value : "Нет данных"),
        sortable: true,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "lifetimeAccount",
        headerName: "Life Duration Account Full (days)",
        minWidth: 60,
        headerClassName: "lifetime-account-header",
        cellClassName: "lifetime-account-cell",
        sortable: true,
        filterOperators: stringOnlyContainsOperators,
      },
      {
        field: "lifetimeDomain",
        headerName: "Lifetime Domain Full (days)",
        minWidth: 60,
        headerClassName: "lifetime-domain-header",
        cellClassName: "lifetime-domain-cell",
        sortable: true,
        filterOperators: stringOnlyContainsOperators,
      },
    ];
  }, [stringOnlyContainsOperators]);

  const fetchAccountsData = useCallback(async () => {
    setLoading(true);
    try {
      const params = {
        startDate: startDate ? startDate.toISOString() : undefined,
        endDate: endDate ? endDate.toISOString() : undefined,
      };

      if (role === "user" && username) {
        params.username = username;
        params.role = role;
      } else if (role === "tlead" && Array.isArray(team)) {
        params.username = username;
        params.role = role;
      }

      const response = await axios.get(
        "https://backend.moorpan.com/get-accounts-google-new",
        {
          params,
          headers: {
            role,
            username,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      let accounts = response.data || [];
      if (role === "tlead" && Array.isArray(team)) {
        accounts = accounts.filter((acc) => team.includes(acc.project));
      }

      setAccountsData(accounts);
      setError(null);
    } catch (err) {
      console.error("[fetchAccountsData] error:", err);
      setError("Ошибка при загрузке данных (Google)");
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate, role, username, team, token]);

  useEffect(() => {
    if (startDate && endDate) {
      fetchAccountsData();
    }
  }, [startDate, endDate, fetchAccountsData]);

  const fetchColumnSettings = useCallback(async () => {
    try {
      const response = await axios.get(
        "https://backend.moorpan.com/get-column-settings",
        {
          params: { username, tableName },
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.data) {
        const visibilityModel = response.data.reduce((acc, col) => {
          acc[col.field] = !col.hide;
          return acc;
        }, {});

        const widths = response.data.reduce((acc, col) => {
          if (col.width) acc[col.field] = col.width;
          return acc;
        }, {});

        setColumnVisibilityModel(visibilityModel);
        setColumnWidths(widths);
      }
    } catch (error) {
      console.error("Error fetching column settings:", error);
    }
  }, [username, tableName, token]);

  useEffect(() => {
    fetchColumnSettings();
  }, [fetchColumnSettings]);

  const handleColumnWidthChange = useCallback(
    async (params) => {
      const updatedWidths = {
        ...columnWidths,
        [params.colDef.field]: params.width,
      };
      setColumnWidths(updatedWidths);

      try {
        await axios.post(
          "https://backend.moorpan.com/save-column-settings",
          {
            username,
            tableName,
            columnConfig: Object.keys(updatedWidths).map((field) => ({
              field,
              width: updatedWidths[field],
              hide: false,
            })),
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log("Column widths saved (Google).");
      } catch (error) {
        console.error("Error saving column widths:", error);
      }
    },
    [username, tableName, columnWidths, token]
  );

  const handleColumnVisibilityModelChange = useCallback(
    async (newVisibilityModel) => {
      setColumnVisibilityModel(newVisibilityModel);

      const columnConfig = baseColumns.map((col) => ({
        field: col.field,
        hide: !newVisibilityModel[col.field],
        width: columnWidths[col.field] || col.minWidth,
      }));

      try {
        await axios.post(
          "https://backend.moorpan.com/save-column-settings",
          {
            username,
            tableName,
            columnConfig,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        console.log("Column visibility saved (Google).");
      } catch (error) {
        console.error("Error saving column visibility:", error);
      }
    },
    [username, tableName, columnWidths, baseColumns, token]
  );

  const filteredData = useMemo(() => {
    return accountsData
      .map((row, index) => ({
        ...row,
        id: `${row.email}-${index}`,
        seller: row.seller || "Unknown",
      }))
      .filter((row) => {
        const dateVal = row.dateOfIssue || "";
        const dateParsed = dayjs(dateVal.trim(), "DD.MM.YYYY").startOf("day");
        if (!dateParsed.isValid()) {
          return false; 
        }
        const matchesDate =
          (!startDate ||
            dateParsed.isSameOrAfter(dayjs(startDate).startOf("day"))) &&
          (!endDate ||
            dateParsed.isSameOrBefore(dayjs(endDate).endOf("day")));
        if (!matchesDate) {
          return false;
        }

        const matchesEmail = searchEmail.trim() === ""
        ? true
        : row.email && row.email.toLowerCase().includes(searchEmail.toLowerCase());
        const matchesId = row.idAccount
          ? row.idAccount.includes(searchId)
          : false;
        const matchesDomain = row.domain
          ? row.domain.toLowerCase().includes(searchDomain.toLowerCase())
          : false;

        let passLifetime = true;
        if (lifetimeFilter === "0-7") {
          passLifetime = row.lifetimeAccount >= 0 && row.lifetimeAccount <= 7;
        } else if (lifetimeFilter === "7-14") {
          passLifetime = row.lifetimeAccount > 7 && row.lifetimeAccount <= 14;
        } else if (lifetimeFilter === "14-31") {
          passLifetime = row.lifetimeAccount > 14 && row.lifetimeAccount <= 31;
        } else if (lifetimeFilter === "31+") {
          passLifetime = row.lifetimeAccount > 31;
        }

        const matchesSeller =
          sellerFilter === ""
            ? true
            : sellerFilter === "Unknown"
            ? row.seller === "Unknown"
            : row.seller === sellerFilter;
        const matchesProject =
          projectFilter === "" || row.project === projectFilter;

        const matchesAccountType =
          accountTypeFilter.length === 0 ||
          accountTypeFilter.includes(row.accountType);

        const matchesClo =
          cloFilter.length === 0 || cloFilter.includes(row.clo);

        const matchesOffer =
          offerFilter.length === 0 || offerFilter.includes(row.offer);

        const matchesStatus =
          statusFilter.length === 0 || statusFilter.includes(row.statusAds);

        let passCost = true;
        const costNum = parseFloat(row.totalCost) || 0;
        if (totalCostRange === "0-500") {
          passCost = costNum <= 500;
        } else if (totalCostRange === "500-999") {
          passCost = costNum > 500 && costNum <= 999;
        } else if (totalCostRange === "1000+") {
          passCost = costNum >= 1000;
        }

        const matchesAccountGeo =
          accountGeoFilter.length === 0 ||
          accountGeoFilter.includes(row.accountGeo);

        const matchesTrafficGeo =
          trafficGeoFilter.length === 0 ||
          trafficGeoFilter.includes(row.trafficGeo);

        return (
          matchesDate &&
          matchesEmail &&
          matchesId &&
          matchesDomain &&
          passLifetime &&
          matchesSeller &&
          matchesProject &&
          matchesAccountType &&
          matchesClo &&
          matchesOffer &&
          matchesStatus &&
          passCost &&
          matchesAccountGeo &&
          matchesTrafficGeo
        );
      });
  }, [
    accountsData,
    searchEmail,
    searchId,
    searchDomain,
    startDate,
    endDate,
    lifetimeFilter,
    sellerFilter,
    projectFilter,
    accountTypeFilter,
    cloFilter,
    offerFilter,
    statusFilter,
    totalCostRange,
    accountGeoFilter,
    trafficGeoFilter,
  ]);

  const handleOpenDialog = useCallback(
    async (domain) => {
      try {
        setDialogError(null);
        setStatusHistory(null);
        setOpenDialog(true);
        const response = await axios.get(
          `https://backend.moorpan.com/get-status-history/${domain}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setStatusHistory(response.data);
      } catch (error) {
        setDialogError(error.response?.data?.message || "Ошибка загрузки данных");
      }
    },
    [token]
  );

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const handleOpenDailyDialog = useCallback(
    (date) => {
      setSelectedDate(date);

      const filteredBySeller = filteredData.filter(
        (acc) =>
          dayjs(acc.dateOfIssue, "DD.MM.YYYY").format("YYYY-MM-DD") === date &&
          acc.seller === selectedSeller
      );

      const grouped = filteredBySeller.reduce((acc, item) => {
        const proj = item.project || "Unknown";
        if (!acc[proj]) {
          acc[proj] = { project: proj, seller: selectedSeller, totalCost: 0 };
        }
        acc[proj].totalCost += parseFloat(item.totalCost) || 0;
        return acc;
      }, {});

      const arr = Object.values(grouped).map((obj, idx) => ({
        id: `${selectedSeller}-${obj.project}-${idx}`,
        ...obj,
        totalCost: obj.totalCost.toFixed(2),
      }));

      setDailyDialogData(arr);
      setOpenDailyDialog(true);
    },
    [filteredData, selectedSeller]
  );

  const handleCloseDailyDialog = useCallback(() => {
    setOpenDailyDialog(false);
    setDailyDialogData([]);
  }, []);

  const sellerDailyData = useMemo(() => {
    if (!selectedSeller) return [];

    const dailyData = {};
    filteredData.forEach((acc) => {
      if (acc.seller === selectedSeller) {
        const date = dayjs(acc.dateOfIssue, "DD.MM.YYYY").format("YYYY-MM-DD");
        if (!dailyData[date]) {
          dailyData[date] = { date, totalCost: 0 };
        }
        dailyData[date].totalCost += parseFloat(acc.totalCost) || 0;
      }
    });

    const rows = Object.values(dailyData).map((item, idx) => ({
      id: `daily-${item.date}-${idx}`,
      ...item,
      totalCost: item.totalCost.toFixed(2),
    }));

    const totalSum = rows.reduce((sum, row) => sum + parseFloat(row.totalCost), 0);
    rows.push({ id: "total", date: "Total", totalCost: totalSum.toFixed(2) });

    return rows;
  }, [filteredData, selectedSeller]);

  const dailyColumnsForSeller = useMemo(() => {
    return [
      {
        field: "date",
        headerName: "Date",
        flex: 1,
        minWidth: 150,
        sortable: true,
        valueFormatter: (params) =>
          params.value === "Total"
            ? "Total"
            : dayjs(params).format("DD.MM.YYYY"),
        sortComparator: (v1, v2, params1, params2) => {
          if (params1.id === "total" || params2.id === "total") return 0;
          return new Date(v1) - new Date(v2);
        },
        renderCell: (params) => {
          if (!params.value || params.value === "Total") return params.value;
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                height: "100%",
              }}
            >
              <Typography
                variant="body2"
                sx={{ marginRight: "8px", fontWeight: "700" }}
              >
                {dayjs(params.value).format("DD.MM.YYYY")}
              </Typography>
              <IconButton
                size="small"
                color="primary"
                onClick={() => handleOpenDailyDialog(params.value)}
              >
                <InfoIcon />
              </IconButton>
            </Box>
          );
        },
      },
      {
        field: "totalCost",
        headerName: "Total Cost",
        flex: 1,
        minWidth: 150,
        sortable: true,
        sortComparator: (v1, v2, params1, params2) => {
          if (params1.id === "total" || params2.id === "total") return 0;
          return parseFloat(v1) - parseFloat(v2);
        },
      },
    ];
  }, [handleOpenDailyDialog]);

  const chartDataDoughnut = useMemo(() => {
    const dataBySeller = {};
    filteredData.forEach((account) => {
      const s = account.seller || "Unknown";
      if (!dataBySeller[s]) dataBySeller[s] = 0;
      dataBySeller[s]++;
    });
    const labels = Object.keys(dataBySeller);
    const data = Object.values(dataBySeller);
    return {
      labels,
      datasets: [
        {
          label: "Accounts by Seller",
          data,
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
            "#FF9F40",
            "#66D9EF",
            "#E57373",
            "#81C784",
            "#FFD700",
          ].slice(0, labels.length),
        },
      ],
    };
  }, [filteredData]);

  const chartOptions = useMemo(() => {
    return {
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              const seller = tooltipItem.label;
              const totalAccounts = filteredData.filter(
                (a) => a.seller === seller
              ).length;
              const buckets = { "0-7": 0, "7-14": 0, "14-31": 0, "31+": 0 };
              filteredData.forEach((acc) => {
                if (acc.seller === seller) {
                  const la = acc.lifetimeAccount || 0;
                  if (la >= 0 && la <= 7) buckets["0-7"]++;
                  else if (la > 7 && la <= 14) buckets["7-14"]++;
                  else if (la > 14 && la <= 31) buckets["14-31"]++;
                  else if (la > 31) buckets["31+"]++;
                }
              });
              return [
                `Seller: ${seller}`,
                `Total Accounts: ${totalAccounts}`,
                `0-7 days: ${buckets["0-7"]}`,
                `7-14 days: ${buckets["7-14"]}`,
                `14-31 days: ${buckets["14-31"]}`,
                `31+ days: ${buckets["31+"]}`,
              ];
            },
          },
        },
      },
    };
  }, [filteredData]);

  const sellerData = useMemo(() => {
    const obj = {};
    filteredData.forEach((acc) => {
      const d = dayjs(acc.dateOfIssue, "DD.MM.YYYY").format("YYYY-MM-DD");
      if (!obj[d]) {
        obj[d] = { totalLeads: 0, totalCost: 0, totalDeposit: 0, cpl: 0 };
      }
      obj[d].totalLeads += acc.totalLeads || 0;
      obj[d].totalCost += parseFloat(acc.totalCost) || 0;
      obj[d].totalDeposit += acc.totalDeposit || 0;
      obj[d].cpl += parseFloat(acc.cpl) || 0;
    });
    return obj;
  }, [filteredData]);

  const chartDataLine = useMemo(() => {
    const sorted = Object.keys(sellerData).sort(
      (a, b) => new Date(a) - new Date(b)
    );
    const leadsData = sorted.map((d) => sellerData[d].totalLeads);

    return {
      labels: sorted,
      datasets: [
        {
          label: "Total Leads",
          data: leadsData,
          borderColor: "#36A2EB",
          backgroundColor: "rgba(54,162,235,0.2)",
          fill: true,
          tension: 0.4,
          pointRadius: 12,
          pointHoverRadius: 16,
        },
      ],
    };
  }, [sellerData]);

  const lineOptions = useMemo(() => {
    return {
      plugins: {
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              const date = tooltipItem.label;
              const d = sellerData[date];
              if (!d) return [];
              const cpl = isNaN(d.cpl) ? "N/A" : d.cpl;
              return [
                `Date: ${date}`,
                `Total Cost: ${d.totalCost}`,
                `Total Deposit: ${d.totalDeposit}`,
                `Total Leads: ${d.totalLeads}`,
                `CPL: ${cpl}`,
              ];
            },
          },
        },
      },
      scales: {
        x: { title: { display: true, text: "Date" } },
        y: { title: { display: true, text: "Total Leads" } },
      },
    };
  }, [sellerData]);

  const totalStats = useMemo(() => {
    const totalAccounts = filteredData.length;
    const totalCostAll = filteredData
      .reduce((sum, acc) => sum + (parseFloat(acc.totalCost) || 0), 0)
      .toFixed(2);
    const totalDepositAll = filteredData.reduce(
      (sum, acc) => sum + (acc.totalDeposit || 0),
      0
    );
    const totalLeadsAll = filteredData.reduce(
      (sum, acc) => sum + (acc.totalLeads || 0),
      0
    );

    const costRecords = filteredData.filter((acc) => parseFloat(acc.totalCost) > 0);
    const cplValues = costRecords.map((acc) => parseFloat(acc.cpl) || 0);
    const cplSum = cplValues.reduce((a, b) => a + b, 0);
    const avgCpl = cplValues.length > 0 ? cplSum / cplValues.length : 0;

    return {
      totalAccounts,
      totalCostAll,
      totalLeadsAll,
      totalDepositAll,
      avgCpl: avgCpl.toFixed(2),
    };
  }, [filteredData]);

  const downloadCSV = () => {
    const visibleCols = renderedColumns.filter(
      (col) => columnVisibilityModel[col.field] !== false
    );
    const headers = visibleCols.map((col) => col.headerName);

    const rows = filteredData.map((row) => {
      return visibleCols.map((col) => {
        const value = row[col.field];
        return value !== undefined && value !== null
          ? String(value).replace(/"/g, '""')
          : "";
      });
    });

    let csvContent = headers.join(",") + "\n";
    rows.forEach((r) => {
      csvContent += r.join(",") + "\n";
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "accounts_google.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderedColumns = useMemo(() => {
    return baseColumns.map((col) => {
      if (col.field === "statusAds") {
        return {
          ...col,
          renderCell: (params) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                variant="body2"
                sx={{ marginRight: "8px", fontWeight: "700" }}
              >
                {params.value}
              </Typography>
              <IconButton
                size="small"
                color="primary"
                onClick={() => handleOpenDialog(params.row.domain)}
              >
                <InfoIcon />
              </IconButton>
            </Box>
          ),
          width: columnWidths[col.field] || col.minWidth,
        };
      }
      return {
        ...col,
        width: columnWidths[col.field] || col.minWidth,
      };
    });
  }, [baseColumns, columnWidths, handleOpenDialog]);

  const totalCostBySellerArr = useMemo(() => {
    const costMap = {};
    filteredData.forEach((acc) => {
      const s = acc.seller || "Unknown";
      const c = parseFloat(acc.totalCost) || 0;
      if (!costMap[s]) costMap[s] = 0;
      costMap[s] += c;
    });
    return Object.keys(costMap).map((seller) => ({
      id: seller,
      seller,
      totalCost: costMap[seller].toFixed(2),
    }));
  }, [filteredData]);

  const totalCostSum = useMemo(() => {
    return totalCostBySellerArr
      .reduce((sum, item) => sum + parseFloat(item.totalCost), 0)
      .toFixed(2);
  }, [totalCostBySellerArr]);

  const rowsWithTotal = useMemo(() => {
    return [
      ...totalCostBySellerArr,
      { id: "total", seller: "Total", totalCost: totalCostSum },
    ];
  }, [totalCostBySellerArr, totalCostSum]);

  const columnsWithDetails = useMemo(() => {
    return [
      {
        field: "seller",
        headerName: "Seller",
        minWidth: 200,
        flex: 1,
        sortable: true,
        sortComparator: (v1, v2, params1, params2) => {
          if (params1.id === "total" || params2.id === "total") return 0;
          return v1.localeCompare(v2);
        },
      },
      {
        field: "totalCost",
        headerName: "Total Cost",
        minWidth: 150,
        flex: 1,
        sortable: true,
        sortComparator: (v1, v2, params1, params2) => {
          if (params1.id === "total" || params2.id === "total") return 0;
          return parseFloat(v1) - parseFloat(v2);
        },
      },
      {
        field: "details",
        headerName: "Details",
        minWidth: 150,
        sortable: false,
        renderCell: (params) =>
          params.row.seller !== "Total" ? (
            <Button
              variant="outlined"
              size="small"
              onClick={() =>
                setSelectedSeller((prev) =>
                  prev === params.row.seller ? null : params.row.seller
                )
              }
            >
              {selectedSeller === params.row.seller ? "Hide" : "Details"}
            </Button>
          ) : null,
      },
    ];
  }, [selectedSeller]);

  const handleExcludedStatusFilter = useCallback(() => {
    if (!isExcludedFilterActive) {
      const remain = accountsData
        .map((r) => r.statusAds)
        .filter((s) => !EXCLUDED_STATUSES.includes(s));
      setStatusFilter([...new Set(remain)]);
    } else {
      setStatusFilter([]);
    }
    setIsExcludedFilterActive((prev) => !prev);
  }, [isExcludedFilterActive, accountsData]);

  const toggleAdvancedSettings = useCallback(() => {
    setShowAdvancedSettings((prev) => !prev);
  }, []);

  const handleOpenTotalDialog = () => setOpenTotalDialog(true);
  const handleCloseTotalDialog = () => setOpenTotalDialog(false);

  const setToday = useCallback(() => {
    setStartDate(dayjs().startOf("day"));
    setEndDate(dayjs().endOf("day"));
  }, []);
  const setYesterday = useCallback(() => {
    setStartDate(dayjs().subtract(1, "day").startOf("day"));
    setEndDate(dayjs().subtract(1, "day").endOf("day"));
  }, []);
  const setLastWeek = useCallback(() => {
    setStartDate(dayjs().subtract(1, "week").startOf("week"));
    setEndDate(dayjs().subtract(1, "week").endOf("week"));
  }, []);
  const setLastMonth = useCallback(() => {
    setStartDate(dayjs().subtract(1, "month").startOf("month"));
    setEndDate(dayjs().subtract(1, "month").endOf("month"));
  }, []);
  const setThisWeek = useCallback(() => {
    setStartDate(dayjs().startOf("week"));
    setEndDate(dayjs().endOf("week"));
  }, []);
  const setThisMonth = useCallback(() => {
    setStartDate(dayjs().startOf("month"));
    setEndDate(dayjs().endOf("month"));
  }, []);
  const setLastThreeMonths = useCallback(() => {
    setStartDate(dayjs().subtract(2, "month").startOf("day"));
    setEndDate(dayjs().endOf("day"));
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ position: "relative" }}>
        {loading ? (
          <Loader />
        ) : error ? (
          <Typography variant="h6" color="error" align="center">
            {error}
          </Typography>
        ) : (
          <>
            {isMobile && (
              <IconButton
                onClick={() => {
                  fetchAccountsData();
                }}
                sx={{
                  position: "fixed",
                  left: "12px",
                  bottom: "12px",
                  zIndex: 999999,
                  backgroundColor: "#0BDF79",
                  color: "#FFFFFF",
                  width: "56px",
                  height: "56px",
                  borderRadius: "50%",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    backgroundColor: "#0ACF6F",
                  },
                }}
              >
                <RefreshIcon />
              </IconButton>
            )}

            <Typography
              variant="h4"
              align="center"
              gutterBottom
              sx={{ margin: "0 0 40px 0" }}
            >
              Accounts Analytics (Google Sheets)
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <DatePicker
                label="From Date"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                format="DD/MM/YYYY"
              />
              <Divider
                orientation={isMobile ? "horizontal" : "vertical"}
                variant="middle"
                flexItem
                sx={{ margin: isMobile ? "24px 0" : "0 12px" }}
              />
              <DatePicker
                label="To Date"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                format="DD/MM/YYYY"
              />
              {!isMobile && (
                <IconButton
                  onClick={() => {
                    fetchAccountsData();
                  }}
                  color="primary"
                  sx={{ marginLeft: "12px" }}
                >
                  <RefreshIcon />
                </IconButton>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: isMobile ? 2 : 6,
                mb: 2,
                mt: 2,
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <Button onClick={setToday} variant="outlined" size="small">
                Today
              </Button>
              <Button onClick={setYesterday} variant="outlined" size="small">
                Yesterday
              </Button>
              <Button onClick={setLastWeek} variant="outlined" size="small">
                Last Week
              </Button>
              <Button onClick={setLastMonth} variant="outlined" size="small">
                Last Month
              </Button>
              <Button onClick={setThisWeek} variant="outlined" size="small">
                This Week
              </Button>
              <Button onClick={setThisMonth} variant="outlined" size="small">
                This Month
              </Button>
              <Button onClick={setLastThreeMonths} variant="outlined" size="small">
                Last 3 months
              </Button>
            </Box>

            {filteredData.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Box sx={{ margin: "0", width: isMobile ? "100%" : "30%" }}>
                  <Typography variant="h5" align="center" gutterBottom>
                    Accounts by Seller
                  </Typography>
                  <Doughnut data={chartDataDoughnut} options={chartOptions} />
                </Box>

                <Box sx={{ margin: "0", width: isMobile ? "100%" : "60%" }}>
                  <Typography variant="h5" align="center" gutterBottom>
                    Total Cost by Seller
                  </Typography>
                  <DataGrid
                    rows={rowsWithTotal}
                    columns={columnsWithDetails}
                    pageSize={5}
                    sx={dataGridStyles}
                    rowsPerPageOptions={[5, 10, 20]}
                    getRowClassName={(params) =>
                      params.id === "total"
                        ? "total-row"
                        : params.indexRelativeToCurrentPage % 2 === 0
                        ? "MuiDataGrid-even-row"
                        : ""
                    }
                  />
                  {selectedSeller && (
                    <Box sx={{ marginTop: 2 }}>
                      <Typography variant="h6" align="center" gutterBottom>
                        Daily Costs for {selectedSeller}
                      </Typography>
                      <DataGrid
                        rows={sellerDailyData}
                        columns={dailyColumnsForSeller}
                        pageSize={5}
                        sx={dataGridStyles}
                        rowsPerPageOptions={[5, 10, 20]}
                        getRowClassName={(params) => {
                          if (params.id === "total") return "total-row";
                          if (params.indexRelativeToCurrentPage % 2 === 0) {
                            return "MuiDataGrid-even-row";
                          }
                          return "";
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            )}

            <Button
              onClick={() => setIsMetricsVisible((prev) => !prev)}
              variant="outlined"
              size="large"
              sx={{ marginBottom: 2, width: isMobile ? "100%" : "auto" }}
            >
              {isMetricsVisible ? "Hide Metrics Overview" : "Show Metrics Overview"}
            </Button>
            {isMetricsVisible && (
              <Box sx={{ margin: "0", width: "100%" }}>
                <Typography variant="h5" align="center" gutterBottom>
                  Accounts Metrics Overview
                </Typography>
                <Line data={chartDataLine} options={lineOptions} />
              </Box>
            )}

            <Typography variant="h5" gutterBottom sx={{ margin: "0 0 12px 0" }}>
              Filter by params
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: isMobile ? "column" : "row",
                gap: 2,
              }}
            >
              <FormControl sx={{ width: isMobile ? "100%" : "49%" }}>
                <Select
                  value={sellerFilter}
                  onChange={(e) => setSellerFilter(e.target.value)}
                  displayEmpty
                  size="small"
                  renderValue={(selected) =>
                    selected === "" ? "All Sellers" : selected
                  }
                >
                  <MenuItem value="">All Sellers</MenuItem>
                  {[...new Set(accountsData.map((acc) => acc.seller || "Unknown"))].map(
                    (slr) => (
                      <MenuItem key={slr} value={slr}>
                        {slr}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
              <FormControl sx={{ width: isMobile ? "100%" : "49%" }}>
                <Select
                  value={projectFilter}
                  onChange={(e) => setProjectFilter(e.target.value)}
                  displayEmpty
                  size="small"
                  renderValue={(selected) =>
                    selected === "" ? "All Buyers" : selected
                  }
                >
                  <MenuItem value="">All Buyers</MenuItem>
                  {[...new Set(accountsData.map((acc) => acc.project))].map((pr) => (
                    <MenuItem key={pr} value={pr}>
                      {pr}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                mb: 2,
                mt: 2,
              }}
            >
              <TextField
                label="Search by Email"
                value={searchEmail}
                onChange={(e) => setSearchEmail(e.target.value)}
                variant="outlined"
                size="small"
                sx={{ width: isMobile ? "100%" : "32%" }}
              />
              <TextField
                label="Search by Account ID"
                value={searchId}
                onChange={(e) => setSearchId(e.target.value)}
                variant="outlined"
                size="small"
                sx={{ width: isMobile ? "100%" : "32%" }}
              />
              <TextField
                label="Search by Domain"
                value={searchDomain}
                onChange={(e) => setSearchDomain(e.target.value)}
                variant="outlined"
                size="small"
                sx={{ width: isMobile ? "100%" : "32%" }}
              />

              <Autocomplete
                multiple
                limitTags={2}
                options={[
                  ...new Set(accountsData.map((row) => row.accountGeo).filter(Boolean)),
                ]}
                value={accountGeoFilter}
                onChange={(event, newValue) => setAccountGeoFilter(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by AccountGeo"
                    placeholder="All"
                    variant="outlined"
                    size="small"
                  />
                )}
                sx={{ width: isMobile ? "100%" : "32%" }}
              />

              <Autocomplete
                multiple
                limitTags={2}
                options={[
                  ...new Set(accountsData.map((row) => row.trafficGeo).filter(Boolean)),
                ]}
                value={trafficGeoFilter}
                onChange={(event, newValue) => setTrafficGeoFilter(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by TrafficGeo"
                    placeholder="All"
                    variant="outlined"
                    size="small"
                  />
                )}
                sx={{ width: isMobile ? "100%" : "32%" }}
              />
            </Box>

            <Typography variant="h5" gutterBottom sx={{ margin: "0 0 12px 0" }}>
              Lifetime Account Filter
            </Typography>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Select
                value={lifetimeFilter}
                onChange={(e) => setLifetimeFilter(e.target.value)}
                size="small"
                displayEmpty
                renderValue={(selected) => (selected === "" ? "All" : selected)}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="0-7">0-7 days</MenuItem>
                <MenuItem value="7-14">7-14 days</MenuItem>
                <MenuItem value="14-31">14-31 days</MenuItem>
                <MenuItem value="31+">31+ days</MenuItem>
              </Select>
            </FormControl>

            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
              <Button onClick={toggleAdvancedSettings} variant="outlined" size="small">
                {showAdvancedSettings
                  ? "Hide Additional Settings"
                  : "Show Additional Settings"}
              </Button>
              <Button onClick={handleExcludedStatusFilter} variant="outlined" size="small">
                {isExcludedFilterActive ? "Show All Data" : "Filter Excluded Statuses"}
              </Button>
            </Box>

            {showAdvancedSettings && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: 2,
                  mb: 2,
                }}
              >
                <Autocomplete
                  multiple
                  limitTags={2}
                  options={[
                    ...new Set(accountsData.map((acc) => acc.accountType).filter(Boolean)),
                  ]}
                  value={accountTypeFilter}
                  onChange={(event, newValue) => setAccountTypeFilter(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Account Type" placeholder="Any" />
                  )}
                  sx={{ width: "19%" }}
                />
                <Autocomplete
                  multiple
                  limitTags={2}
                  options={[
                    ...new Set(accountsData.map((acc) => acc.clo).filter(Boolean)),
                  ]}
                  value={cloFilter}
                  onChange={(event, newValue) => setCloFilter(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label="Select CLO" placeholder="Any" />
                  )}
                  sx={{ width: "19%" }}
                />
                <Autocomplete
                  multiple
                  limitTags={2}
                  options={[
                    ...new Set(accountsData.map((acc) => acc.offer).filter(Boolean)),
                  ]}
                  value={offerFilter}
                  onChange={(event, newValue) => setOfferFilter(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Offer" placeholder="Any" />
                  )}
                  sx={{ width: "19%" }}
                />
                <Autocomplete
                  multiple
                  id="select-status"
                  options={[
                    ...new Set(accountsData.map((r) => r.statusAds).filter(Boolean)),
                  ]}
                  value={statusFilter}
                  onChange={(event, newValue) => setStatusFilter(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Status" placeholder="All" />
                  )}
                  getOptionLabel={(option) => (option ? option.toString() : "")}
                  sx={{ width: "19%" }}
                />
                <Autocomplete
                  options={["", "0-500", "500-999", "1000+"]}
                  value={totalCostRange}
                  onChange={(event, newValue) => setTotalCostRange(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label="Total Cost Range" placeholder="All" />
                  )}
                  sx={{ width: "19%" }}
                />
              </Box>
            )}
            <Box sx={{ mb: 2, mt: 2, display: "flex", gap: 2 }}>
              {role === "admin" && (
                <Button variant="outlined" onClick={downloadCSV}>
                  Download CSV
                </Button>
              )}
              <Button variant="outlined" onClick={handleOpenTotalDialog}>
                Open total dialog
              </Button>
            </Box>
            <DataGrid
              rows={filteredData}
              columns={renderedColumns}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 25, page: 0 },
                },
              }}
              pageSizeOptions={[25, 50, 100, { value: -1, label: "All" }]}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
              onColumnWidthChange={handleColumnWidthChange}
              sx={{ ...dataGridStyles, ...dataGridStylesSticky }}
              getRowClassName={(params) =>
                RED_STATUSES.includes(params.row.statusAds)
                  ? "row-red"
                  : params.indexRelativeToCurrentPage % 2 === 0
                  ? "MuiDataGrid-even-row"
                  : ""
              }
            />
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>Status History</DialogTitle>
              <DialogContent>
                {dialogError ? (
                  <Typography color="error">{dialogError}</Typography>
                ) : statusHistory ? (
                  <Box>
                    {statusHistory.map((item, index) => (
                      <Typography key={index}>
                        {index + 1}.{" "}
                        {dayjs(item.date).format("DD/MM/YYYY HH:mm")} -{" "}
                        {item.status}
                      </Typography>
                    ))}
                  </Box>
                ) : (
                  <Typography>Loading...</Typography>
                )}
              </DialogContent>
              <DialogActions>
                <IconButton size="small" color="primary" onClick={handleCloseDialog}>
                  <CloseIcon />
                </IconButton>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openDailyDialog}
              onClose={handleCloseDailyDialog}
              maxWidth="md"
              fullWidth
            >
              <DialogTitle>{`Details for ${selectedSeller} on ${selectedDate}`}</DialogTitle>
              <DialogContent>
                <DataGrid
                  rows={dailyDialogData}
                  columns={[
                    {
                      field: "seller",
                      headerName: "Seller",
                      flex: 1,
                      minWidth: 150,
                    },
                    {
                      field: "project",
                      headerName: "Buyer",
                      flex: 1,
                      minWidth: 200,
                    },
                    {
                      field: "totalCost",
                      headerName: "Total Cost",
                      flex: 1,
                      minWidth: 150,
                      sortComparator: (v1, v2) => {
                        const num1 = parseFloat(v1) || 0;
                        const num2 = parseFloat(v2) || 0;
                        return num2 - num1;
                      },
                    },
                  ]}
                  pageSize={5}
                  rowsPerPageOptions={[5, 10]}
                  sx={dataGridStyles}
                  getRowClassName={(params) => {
                    if (params.indexRelativeToCurrentPage % 2 === 0) {
                      return "MuiDataGrid-even-row";
                    }
                    return "";
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDailyDialog} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openTotalDialog}
              onClose={handleCloseTotalDialog}
              maxWidth="xs"
              fullWidth
            >
              <DialogTitle>Total Info</DialogTitle>
              <DialogContent>
                <Typography>Accounts Count: {totalStats.totalAccounts}</Typography>
                <Typography>Total Cost: {totalStats.totalCostAll}</Typography>
                <Typography>Total Leads: {totalStats.totalLeadsAll}</Typography>
                <Typography>Total Deposit: {totalStats.totalDepositAll}</Typography>
                <Typography>Average CPL: {totalStats.avgCpl}</Typography>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseTotalDialog}>Close</Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default AccountsAnalyticsGoogle;
